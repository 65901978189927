<template>
	<header class="header" :class=" {'bordered': border, 'platform': platform, 'center': forceCenter} ">
		<div class="header__main">
			<div class="header__logo-wrapper">
				<b-logo
					:tac="forceCenter || (loginPage && $windowWidth > 375)"
				/>
			</div>
			<div v-if="!loginPage" class="header__logout" @click="$store.dispatch('auth/logout')">
				<svg-icon icon-class="exit" class="exit__logo"></svg-icon>
				<span class="exit__title">
					Выйти
				</span>
			</div>
		</div>
		<div class="selector" v-if="platform">
			<div class="header__platform-wrapper">
				<span class="platform__text">Платформа:</span>
				<div class="platform-select__wrapper">
					<b-platoform-select/>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import logo from "@/components/basic/logo"
import platformSelect from "@/components/platformSelect";

export default {
	name: 'b-header',
	components: {
		"b-logo": logo,
		"b-platoform-select": platformSelect
	},
	props: {
		border: {
			type: Boolean,
		},
		platform: {
			type: Boolean,
		},
		'force-center': {
			type: Boolean,
		},
		'login-page': {
			type: Boolean,
		}
	}
}
</script>

<style scoped lang="sass">
.header
	display: flex
	justify-content: space-between

	&__main
		display: flex
		flex-grow: 1
		align-items: center
		justify-content: space-between

	&__logout
		cursor: pointer
		padding: 10px
		margin-right: 19px
		background: transparent
		transition: background .2s ease
		border-radius: 9999px

		&:hover
			background: rgba(0,0,0,.05)

	&__logo-wrapper
		padding: 24px

	&__platform-wrapper
		padding: 24px
		height: 100%
		width: 100%
		display: flex
		justify-content: center
		align-items: center

	&.bordered
		border-bottom: 1px solid $deviders
	&.center
		justify-content: center

	.selector
		border-left: 1px solid $deviders

.platform
	&__text
		font-weight: 600
		font-size: 14px
		line-height: 28px
		display: none
		margin-right: 13px

.exit__title
	display: none

@media screen and (min-width: $xs)
	.header:not(.platform)
		justify-content: center

	.header
		&__platform-wrapper
			padding: 24px 40px
	

@media screen and (min-width: $sm)
	.header
		&__logout
			cursor: pointer
			padding: 10px 7px
			margin-right: 18px
			border-radius: 9px

	.exit__title
		display: inline-block
		margin-left: 10px
		
	.platform
		&__text
			display: inline

	.platform
		&__text
			display: inline



</style>