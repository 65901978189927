<template>
	<div class="dropdown">
		<div @click="toggleDropdown" class="toggler">
			<slot></slot>
		</div>

		<transition name="fade">
			<div
				v-if="value ?? state"
				class="dropdown__element"
				:class="{ 'dropdown__element--left': left }"
			>
				<header
					class="dropdown__header header"
					v-if="title || close"
					:class="{ 'header--bordered': headerBorder }"
				>
					<div class="header__text">
						{{title}}
					</div>
					<div v-if="close" class="header__close" @click="toggleDropdown">
						<img src="/img/cross.svg" alt="cross">
					</div>
				</header>
				<main>
					<slot name="dropdown"></slot>
				</main>
			</div>
		</transition>

	</div>
</template>

<script>
export default {
	name: "b-dropdown",
	data: () => ({
		state: false
	}),
	props: {
		title: {
			type: [String, Number],
			default: ""
		},
		close: {
			type: Boolean,
			default: false
		},
		left: {
			type: Boolean,
			default: false
		},
		'header-border': {
			type: Boolean,
			default: false
		},
		value: {
			type: Boolean,
			default: undefined
		}
	},
  methods: {
    toggleDropdown() {
			const newVal = this.value ?? this.state;
      this.state = !newVal
			this.$emit('change', !newVal)
    },
    closeDropdown(e) {
      if (!this.$el.contains(e.target)) {
        this.state = false
				this.$emit('change', false)
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown)
  }

}
</script>

<style scoped lang='sass'>
.dropdown
	display: inline-block
	position: relative

	&__element
		position: absolute
		bottom: -4px
		right: 0
		transform: translateY(100%)
		padding: 4px
		background: $white
		box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.16)
		border-radius: 9px
		min-width: 199px
		z-index: 100

		
		&.fade
			&-enter-active, &-leave-active
				transition: all .2s ease
			
			&-enter, &-leave-to
				opacity: 0
				transform: translateY(calc(5px + 100%))

		&--left
			bottom: initial
			right: initial
			left: -12px
			transform: translateX(-100%)

			&::after
				content: ''
				height: 30px
				width: 30px
				background-color: $white
				
				position: absolute
				top: 50%
				right: 0
				border-radius: 7px
				border: 1px solid rgba(0, 0, 0, 0.06)
				clip-path: polygon(0% 0%, 0% 100%, 100% 100%)
				transform: translateY(-50%) translateX(calc(50% - 1px)) rotate(225deg)
			
			&.fade
				&-enter, &-leave-to
					transform: translateY(5px) translateX(-100%)

	&__header
		padding: 6px 2px 8px 24px
		display: flex
		justify-content: space-between
		align-items: center


.header
	&__text
		font-weight: 600
		font-size: 16px
		line-height: 16px
		color: $main-dark

	&__close
		// border: 1px solid black
		border-radius: $default-border-radius
		padding: 10px
		height: 33px
		width: 33px
		display: flex
		justify-content: center
		align-items: center
		margin-left: 15px
		cursor: pointer
		transition: background-color .2s ease
		user-select: none

		&:hover
			background-color: $gray-light-4

	&--bordered
		border-bottom: 1px solid rgba(0, 0, 0, 0.06)

.toggler
	display: inline
	cursor: pointer

</style>