<template>
	<div class="logo">
		<img :src="`/img/logo${tac ? '-tac' : ''}.svg`" alt="logo">
	</div>
</template>

<script>
export default {
	name: "logo",
	props: {
		width: {
			type: Number,
			default: 116
		},
		height: {
			type: Number,
			default: 36
		},
		tac: {
			type: Boolean
		}
	},
	computed: {
		styles(){
			return {
				width: this.width + "px",
				height: this.height + "px",
			}
		}
	}
}
</script>

<style scoped lang="sass">
.logo
	width: 116px
	height: 36px
</style>