<template>
	<b-dropdown
		title="Платформы"
		close
		@change="(newVal) => state = disabled ? state : newVal"
		:value="state"
	>
		<div class="toggler" :class="{ 'active': state && !disabled, 'toggler--min': min, 'toggler--disabled': disabled }">
			<div class="toggler__content" :key="current.title">
				<div class="toggler__logo">
					<img :src="`/img/platforms/${selected.icon}.svg`" :alt="selected.icon">
				</div>
				<div class="toggler__title">
					{{selected.title}}
				</div>
				<div class="toggler__icon">
					<img src="/img/arrow-dropdown.svg" alt="dropdown icon">
				</div>
			</div>
		</div>

		<template v-slot:dropdown>
			<ul class="platforms-list" v-if="!disabled">
				<li
					v-for="platform in platforms"
					:key="platform.title"
					class="platform"
					:class="{'selected': platform == selected}"
					@click="platformSelect(platform)"
				>
					<div class="platform__current">
						<img v-if="platform == selected" src="/img/check.svg" alt="check">
					</div>
					<div class="platform__icon">
						<!-- <img :src="`/img/platforms/${platform?.icon ?? '0'}.svg`" :alt="platform.icon"> -->
						<svg-icon :icon-class="'platform-' + platform?.icon" style="font-size: 18px"/>
					</div>
					<div class="platform__text">
						{{platform.title}}
					</div>
				</li>
			</ul>
		</template>
	</b-dropdown>
</template>

<script>
import dropdown from "@/components/basic/dropdown.vue"
import {mapGetters} from "vuex"
export default {
	name: "platform-select",
	components: {
		"b-dropdown": dropdown
	},
	props: {
		min: {
			type: Boolean
		},
		disabled: {
			type: Boolean
		}
	},
	methods: {
		platformSelect(platform){
			this.$store.commit('platforms/setSelectedPlatform', platform);
			this.state = false;
		}
	},
	computed: {
		...mapGetters('platforms', {
			selected: 'selected',
			platforms: 'list',
			current: 'current'
		}),
	},
	data: () => ({
		state: false
	})
}
</script>

<style scoped lang="sass">

.platforms-list
	padding: 0
	margin: 0
	list-style: none

.platform
	padding: 6px 7px
	border-radius: $default-border-radius
	cursor: pointer
	font-size: 12px
	line-height: 16px
	display: flex
	align-items: center

	&__current
		width: 19px
		padding: 0 2px
		user-select: none

	&__icon
		margin-right: 8px
		user-select: none

		img
			display: block

	&:not(:last-of-type)
		margin-bottom: 3px

	&:hover
		background-color: $dropdown-hower
		color: $accent-buttons

	&.selected
		background-color: $dropdown-hower
		color: $accent-buttons

.toggler
	border: 1px solid $deviders
	border-radius: 9px
	transition: background-color .1s ease

	&.active, &:not(.toggler--disabled):hover
		background-color: $white

	&__content
		display: flex
		// padding: 5px 9px 8px 8px
		padding: 8px 10px 8px 8px
		align-items: center
		user-select: none

	&__logo
		margin-right: 8px
		user-select: none
		img
			display: block

	&__title
		margin-left: -5px
		padding-top: 1px
		display: none
		flex: 1 0
		font-size: 14px

	&.toggler--min
		width: auto
		.toggler__title
			display: none



@media screen and (min-width: $sm)
	.toggler
		width: 147px
		&__title
			display: block
</style>